import { Document, Paragraph, TextRun } from 'docx';

import { substringReplace } from '../helpers/substringReplace';

const reSpeaker = /(SPEAKER_)\w+/g;
const reProtocol = /(Тема встречи|Список участников|Резюме встречи|Краткий обзор:|Подробный анализ:|Основные темы обсуждения:|Подробное описание каждой темы:|Проблемы и вызовы:|Проблемы и вызовы, которые были выявлены:|Решения и действия:|Четко сформулированные решения по каждому вопросу:|Конкретные задачи:|Выводы:)/gi;

const getNewWordDocument = (content: Paragraph[]) => {
    return new Document({
        sections: [
            {
                properties: {},
                children: content
            }
        ]
    })
}

export const getTranscriptionDocFile = (rawText: string) => {
    const paragraphsChildrens: TextRun[][] = [];

    substringReplace(rawText.replaceAll('**', '')).split('\n')
        .filter((item: string) => item)
        .forEach((item: string, index: number) => {
            const breakString = (index !== 0 && item.includes('SPEAKER')) ? 1 : 0;
            const textRun = new TextRun({
                text: ` ${item}`,
                break: breakString,
            });
            let children = [ textRun ];

            const match = item.match(reSpeaker);

            if (match) {
                children = [
                    new TextRun({
                        text: '',
                        break: breakString,
                    }),
                    new TextRun({
                        text: match[0],
                        bold: match ? true : false
                    }),
                    new TextRun({
                        text: item.replace(reSpeaker, ''),
                    }),
                ]
            } else {
                paragraphsChildrens.at(-1)?.push(textRun);
                return;
            }

            paragraphsChildrens.push(children)
        });

    const paragraphs: Paragraph[] = paragraphsChildrens.map((item) => {
        return new Paragraph({
            children: item
        });
    });

    return getNewWordDocument(paragraphs);
}

export const getProtocolDocFile = (rawText: string) => {
    // handleSpeakers - флаг выделения спикеров
    const paragraphsChildrens: TextRun[][] = [];

    const textArr: string[] = [];

    substringReplace(rawText.replaceAll('**', '')).split('\n').forEach((item: string) => {
        if (!item && !textArr.length) {
            return;
        }
        textArr.push(item)
    })

    textArr
        .forEach((item: string) => {
            const breakString = 0;

            let textItem = item.replaceAll('"', '');
            // приходится делать такую замену по причине странных символов в составе строки - из-за них слово "Список" в .docx потом подчёркивается красным как содержащий ошибку
            textItem = textItem === 'Cписок участников' ? textItem.replace('Cписок участников', 'Список участников') : textItem;

            const match = textItem.match(reProtocol);

            const textRun = new TextRun({
                text: ` ${textItem}`,
                break: breakString,
                bold: match ? true : false
            });
            const children = [ textRun ];

            paragraphsChildrens.push(children)
        });

    const paragraphs: Paragraph[] = paragraphsChildrens.map((item) => {
        return new Paragraph({
            children: item
        });
    });

    return getNewWordDocument(paragraphs);
}