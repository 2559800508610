export enum ProgressMessage {
  RECEIVED_FILE_TO_TRANSCRIBE = 'RECEIVED_FILE_TO_TRANSCRIBE',
  RECEIVED_REQUEST_TO_TRANSCRIBE = 'RECEIVED_REQUEST_TO_TRANSCRIBE',
  TRANSCRIBING = 'TRANSCRIBING',
  LOADING_MODEL_TOOK = 'LOADING_MODEL_TOOK',
  LOADING_AUDIO_TOOK = 'LOADING_AUDIO_TOOK',
  TRANSCRIPTION_TOOK = 'TRANSCRIPTION_TOOK',
  ALIGNMENT_MODEL_LOADED = 'ALIGNMENT_MODEL_LOADED',
  LOADING_ALIGNMENT_MODEL_TOOK = 'LOADING_ALIGNMENT_MODEL_TOOK',
  ALIGNMENT_TOOK = 'ALIGNMENT_TOOK',
  LOADING_DIARIZATION_TOOK_MODEL = 'LOADING_DIARIZATION_TOOK_MODEL',
  STARTING_DIARIZATION = 'STARTING_DIARIZATION',
  DIARIZATION_TOOK = 'DIARIZATION_TOOK',
  TRANSCRIPTION_COMPLETED = 'TRANSCRIPTION_COMPLETED',
  CLEARING_TEXT = 'CLEARING_TEXT',
  ERROR = 'ERROR',
}

export enum ProgressMessageTranslate {
  RECEIVED_FILE_TO_TRANSCRIBE = 'Запись в очереди на обработку, ожидайте, может занять некоторое время 1/9',
  RECEIVED_REQUEST_TO_TRANSCRIBE = 'Подготовка к распознаванию 2/9',
  TRANSCRIBING = 'Транскрибация',
  LOADING_MODEL_TOOK = 'Подготовка к распознаванию 3/9',
  LOADING_AUDIO_TOOK = 'Распознавание записи началось, это может занять некоторое время 4/9',
  TRANSCRIPTION_TOOK = 'Подготовка к определению спикеров 5/9',
  ALIGNMENT_MODEL_LOADED = 'Подготовка к определению спикеров 5/9',
  LOADING_ALIGNMENT_MODEL_TOOK = 'Подготовка к определению спикеров 6/9',
  ALIGNMENT_TOOK = 'Подготовка к определению спикеров 6/9',
  LOADING_DIARIZATION_TOOK_MODEL = 'Подготовка к определению спикеров 7/9',
  STARTING_DIARIZATION = 'Определяем спикеров, это может занять некоторое время 8/9',
  DIARIZATION_TOOK = 'Диаризация',
  TRANSCRIPTION_COMPLETED = 'Определение спикеров завершено',
  CLEARING_TEXT = 'Готовим текст к публикации 9/9',
  ERROR = 'Ошибка',
}