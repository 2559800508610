import React, { useState, useEffect } from 'react';
import { Packer } from 'docx';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import { Except } from "type-fest";

import { FileUpload } from '../Uikit/FileUpload';
import { Attachment } from '../Uikit/Attachment';
import { Modal } from '../Uikit/Modal/Modal';
import { Button } from '../Uikit/Button';
import { ProcessingLoader } from '../Uikit/ProcessingLoader';
import { RecognitionTabs } from '../Uikit/RecognitionTabs';
import { flash } from '../Uikit/Notification/flash';
import { getTranscriptionDocFile, getProtocolDocFile } from '../helpers/docFile';
import NotificationConnect, { NotificationSubscribe } from '../api/wss/Notifications';
import { WssMessage } from "../types/WssMessage";
import { ProgressMessage } from "../types/ProgressMessage";

declare global {
  interface Window {
    traceId: string;
  }
}

interface IConfirmModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (a: any) => void;
}

const ConfirmModal = ({ isOpen, onClose, ...rest }: IConfirmModalProps) => {
  return (
      <Modal isOpen={isOpen} onClose={onClose} title="Вы уверены?" className="!w-142 !max-w-142">
          <ModalContent onClose={onClose} {...rest} />
      </Modal>
  );
};

const ModalContent = ({
    onClose,
    onSubmit,
}: Except<IConfirmModalProps, "isOpen">) => {
    const handleSubmit = (e: any) => {
        onSubmit(e);
        onClose();
    };

    return (
        <>
            <Modal.Body>
                <p>
                    Все полученные ранее данные будут утеряны
                </p>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    key={1}
                    onClick={onClose}
                    variant="outline"
                    size={"medium"}
                    color={"secondary"}
                    className="border-[#EAEDF3]"
                >
                    Отмена
                </Button>
                <Button key={2} onClick={handleSubmit}>
                    Загрузить новый файл
                </Button>
            </Modal.Footer>
        </>
    );
};

interface IProgressData {
    traceId: string,
    eventType: string,
}

const beforeUnloadHandler = (event: any) => {
    event.preventDefault();
    event.returnValue = true;
};

const fileErrorMsg = "Ошибка при обработке файла, попробуйте еще раз";
const genericErrorMsg = "Что-то пошло не так";

const showErrorMsg = (msg: string) => flash.error(msg);

export const NotesLogging = () => {
    const [file, setFile] = useState<File | null>(null);

    const [transcriptionContent, setTranscriptionContent] = useState<string | null>(null);
    const [protocolContent, setProtocolContent] = useState<string | null>(null);
    const [isFileProcessingButtonHidden, setIsFileProcessingButtonHidden] = useState(false);
    const [isRecognitionProcessing, setIsRecognitionProcessing] = useState(false);
    const [isRecognitionCompleted, setIsRecognitionCompleted] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    // ws
    const [traceId, setTraceId] = useState<null | string>(null);
    const [clientId, setClientId] = useState<null | string>(null);
    const [progressData, setProgressData] = useState<IProgressData | null>(null);
    const [progressMessage, setProgressMessage] = useState<string | undefined>(ProgressMessage.RECEIVED_FILE_TO_TRANSCRIBE);
    const [fileLoadedSize, setFileLoadedSize] = useState(0);
    const [fileTotalSize, setFileTotalSize] = useState(0);
    const [fileUploadRequest, ] = useState<XMLHttpRequest | null>(new XMLHttpRequest());
    const [isGotGenericError, setIsGotGenericError] = useState(false);
    const controller = new AbortController();
    const signal = controller.signal;

    const uploadFile = async (file: File) => {
        setFile(file);
    };

    const handleSubmit = async (file: File, clientId: string) => {
        if (!file || (file && !navigator.onLine)) {
            showErrorMsg(genericErrorMsg);
            return;
        }

        setIsGotGenericError(false);

        const formData = new FormData();
        formData.append("file", file);

        const newTraceId = uuidv4();
        setTraceId(newTraceId);

        formData.append("traceId", newTraceId);
        formData.append("clientId", String(clientId));

        try {
            setIsFileProcessingButtonHidden(true);

            fileUploadRequest?.upload.addEventListener("progress", (event: { total: number; loaded: number; }) => {
                progressHandler(event);
            });
            fileUploadRequest?.upload.addEventListener("load", (event: any) => {
                setIsRecognitionProcessing(true);
            });
            fileUploadRequest?.addEventListener("loadend", (event) => {
                if (fileUploadRequest?.readyState === 4 && fileUploadRequest?.status === 200) {
                    const response = JSON.parse(fileUploadRequest?.response);
        
                    setTranscriptionContent(response.data.transcription);
                    setIsRecognitionCompleted(true);
                    setProgressMessage(undefined);
                    // setFileUploadRequest(null);
                }
            });
            fileUploadRequest?.open("POST", "/api/transcribe", true);
            fileUploadRequest?.send(formData);

        } catch (e: any) {
            setIsGotGenericError(true);
            setIsFileProcessingButtonHidden(false);
        } finally {
            setIsRecognitionProcessing(false);
        }
    }

    function progressHandler(ev: { total: number; loaded: number; }) {
        let totalSize = ev.total; // total size of the file in bytes
        let loadedSize = ev.loaded; // loaded size of the file in bytes

        setFileLoadedSize(loadedSize);
        setFileTotalSize(totalSize);
    }

    const fetchProtocol = async (transcriptionContent: string) => {
        try {
            let response = await fetch("/api/protocol", {
                method: "POST",
                body: String(transcriptionContent),
                signal
            });

            if (!response.ok) {
                throw new Error(`${response.status} ${response.statusText}`)
            }

            let result = await response.json();

            if (!result.success) {
                showErrorMsg(fileErrorMsg);
                resetStateHandler();
                return;
            }

            if (!protocolContent) {
                setProtocolContent(result.data.transcription);
            }
        } catch (e: any) {
            setIsGotGenericError(true)
        }
    };

    useEffect(() => {
        if (isGotGenericError) {
            showErrorMsg(genericErrorMsg);
        }
    }, [isGotGenericError])

    useEffect(() => {
        NotificationConnect();
        NotificationSubscribe(async (message: WssMessage) => {
            if (message.clientId) {
                // /user/topic/sesstion
                setClientId(message.clientId)
                return;
            }
            // /user/topic/progress
            setProgressData(message);
        });
        // Отслеживаем наличие сети. 
        window?.addEventListener("offline", (e) => {
            // При обрыве сети сбрасываем все загрузки
            setIsGotGenericError(true)
            resetStateHandler();
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // Отслеживание попытки закрытия окна/вкладки или перезагрузки страницы во время активной бработки аудиозаписи
        if ((isRecognitionProcessing && !isRecognitionCompleted) || (isRecognitionCompleted && !protocolContent) || protocolContent) {
            window.addEventListener("beforeunload", beforeUnloadHandler);
            return;
        }
        window.removeEventListener("beforeunload", beforeUnloadHandler, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRecognitionProcessing, isRecognitionCompleted, protocolContent])

    useEffect(() => {
        if (progressData?.traceId === traceId) {
            setProgressMessage(progressData?.eventType);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progressData])

    useEffect(() => {
        if (file && transcriptionContent) {
            fetchProtocol(String(transcriptionContent));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcriptionContent])

    useEffect(() => {
        if (traceId) {
            console.log(`traceId: ${traceId}`);
            if (!window.traceId) {
                window.traceId = traceId;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [traceId])

    const removeFileHandler = async (e: any, fileUploadRequest: XMLHttpRequest | null = null ) => {
        e?.stopPropagation();
        e?.preventDefault();

        setFile(null);
        setTranscriptionContent(null);
        setIsFileProcessingButtonHidden(false);

        fileUploadRequest?.abort();
    };

    const transcriptionToWordHandler = (content: string, name: string) => {
        Packer.toBlob(getTranscriptionDocFile(content)).then((blob) => {
            saveAs(blob, `${name}.docx`);
        });
    }

    const protocolToWordHandler = (content: string, name: string) => {
        Packer.toBlob(getProtocolDocFile(content)).then((blob) => {
            saveAs(blob, `${name}.docx`);
        });
    }

    const resetStateHandler = (e?: any) => {
        setFile(null);
        removeFileHandler(e);
        setProtocolContent(null);
        setTranscriptionContent(null);
        setIsRecognitionCompleted(false);
        setIsRecognitionProcessing(false);
        setTraceId(null);
        setProgressMessage(ProgressMessage.RECEIVED_FILE_TO_TRANSCRIBE);
        setFileLoadedSize(0);
        setFileTotalSize(0);
        controller.abort();

        fileUploadRequest?.abort();

        window.removeEventListener("beforeunload", beforeUnloadHandler, true);
    }

    const onConfirmModalClose = () => {
        setIsConfirmModalOpen(false);
    }

    const handleOpenConfirmModal = () => {
        setIsConfirmModalOpen(true);
    }

    useEffect(() => {
        if (!fileUploadRequest) {
            setFileLoadedSize(0);
            setFileTotalSize(0);
        }
    }, [fileUploadRequest])

    return (
        <>
            {isConfirmModalOpen && (
                <ConfirmModal
                    isOpen={isConfirmModalOpen}
                    onClose={onConfirmModalClose}
                    onSubmit={resetStateHandler}
                />
            )}
            <div className="max-w-[1220px] h-full mx-auto px-7.5 py-7.5 flex flex-col">
                <div className="flex justify-between mb-6">
                    <h1>Протоколирование записей</h1>
                    {!isFileProcessingButtonHidden && <Button
                        onClick={() => {
                            handleSubmit(file as File, String(clientId));
                        }}
                        disabled={!file || !clientId || isGotGenericError}
                    >
                        Начать обработку
                    </Button>}
                    {(file && !!protocolContent) && <Button
                        onClick={handleOpenConfirmModal}
                    >
                        Загрузить новый файл
                    </Button>}
                </div>
                <div className="flex gap-5 items-center justify-center grow">
                    {!file && (
                        <FileUpload
                            onChange={uploadFile}
                            accept={{
                                "audio/mpeg": [],
                                "audio/wav": [],
                                "video/mp4": [],
                                "video/avi": [],
                                "video/webm": [],
                            }}
                            acceptDescription=".mp4, .avi, .webm, .mp3, .wav"
                            className="w-full"
                        />
                    )}
                    {file && !isRecognitionCompleted && !isRecognitionProcessing && (
                        <Attachment 
                            file={file}
                            removeFile={async (e) => {
                                await removeFileHandler(e, fileUploadRequest);
                                // setFileUploadRequest(null);
                            }}
                            fileLoadedSize={fileLoadedSize}
                            fileTotalSize={fileTotalSize}
                            isProcessing={!!fileLoadedSize}
                        />
                    )}
                    {file && isRecognitionProcessing && !isRecognitionCompleted && (
                        <ProcessingLoader 
                            progressMessage={progressMessage as ProgressMessage}
                            traceId={traceId}
                        />
                    )}
                    {file && isRecognitionCompleted && (
                        <RecognitionTabs 
                            fileName={file?.name}
                            transcriptionContent={transcriptionContent}
                            protocolContent={protocolContent}
                            transcriptionToWordHandler={transcriptionToWordHandler}
                            protocolToWordHandler={protocolToWordHandler}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
