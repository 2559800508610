import React, { ForwardedRef, forwardRef } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { flash } from "./Notification/flash";
import { Icon, Icons } from "./Icon/Icon";
import { UIErrorMessages } from "../Enums";
import { getFileSizeErrorMessage } from "../helpers/file";
import { useCombinedRefs } from "../hooks/useCombinedRef";

export interface FileSizeByType {
    [key: string]: number;
}

interface FileUploadProps {
    className?: string;
    onChange?: (file: File) => void;
    maxSize?: FileSizeByType | number;
    accept?: Accept;
    acceptDescription?: string;
    multiple?: boolean;
}

export const FileUpload = forwardRef(
    (
        {
            className,
            onChange,
            maxSize,
            accept = {},
            acceptDescription = "",
            multiple = false,
        }: FileUploadProps,
        ref: ForwardedRef<HTMLInputElement>,
    ) => {
        const inputRef = useCombinedRefs(ref);
        const { getRootProps, getInputProps, fileRejections } = useDropzone({ onDrop, accept, onDropRejected });

        const isMaxSizeValid = (file: File): boolean => {
            if (!maxSize) {
                return true;
            }

            if (typeof maxSize === "number") {
                return file.size < maxSize;
            }

            return Object.keys(maxSize).some((type) => {
                if (RegExp(type).exec(file.type)) {
                    return file.size < maxSize[type];
                }

                return false;
            });
        };

        function onDrop<T extends File>(files: T[]) {
            if (!files?.length) {
                return;
            }

            if (!isMaxSizeValid(files[0])) {
                flash.error(getFileSizeErrorMessage("2 ГБ"));

                return;
            }

            onChange?.(files[0]);
        }

        function onInputChange(e: any) {
            const {
                target: { files },
            } = e;

            if (!files?.length) {
                return;
            }

            if (!Object.keys(accept).some((type) => RegExp(type).exec(files[0].type))) {
                flash.error(UIErrorMessages.FILE_EXTENSION_ERROR);
                return;
            }

            if (!isMaxSizeValid(files[0])) {
                flash.error(getFileSizeErrorMessage("2 ГБ"));
                return;
            }

            onChange?.(files[0]);
        }

        function onDropRejected() {
            fileRejections.forEach((f) => {
                f.errors.forEach((e) => {
                    if (e.code === "file-invalid-type") {
                        flash.error(UIErrorMessages.FILE_EXTENSION_ERROR);
                    }
                });
            });
        }

        return (
            <div
                {...getRootProps({
                    className:
                        "group flex flex-col items-center p-4.5 max-w-200 border border-dashed border-gray-light rounded-lg cursor-pointer hover:border-primary " +
                        className,
                })}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (inputRef) {
                        inputRef.current.click();
                    }
                }}
            >
                <input {...getInputProps({ multiple })} onChange={onInputChange} ref={inputRef} />
                <Icon
                    className="mb-2"
                    icon={Icons.Upload}
                    width="58px"
                    height="58px"
                    color="fill-gray-blue group-hover:fill-blue"
                />
                <div className="pb-1 text-gray-dark">
                    Выберите аудио- видеофайл или перетащите мышью
                </div>
                <div className="flex flex-col gap-1 items-center">
                    <div className="text-xs text-gray-text">{acceptDescription} до 2ГБ</div>
                </div>
            </div>
        );
    },
);
